import React, { lazy, Suspense } from 'react';

const HomeTitle = lazy(() => import(/* webpackMode: "lazy", preload: true, webpackChunkName: 'HomeTitleLazy' */ './HomeTitle'));

function HomeTitleLazyPreload(props) {
    return (
        <div>
            <Suspense fallback={<div>Loading HomeTitle ...</div>}>
                <HomeTitle {...props} />
            </Suspense>
        </div>
    );
}

export default HomeTitleLazyPreload;